$headerColor: '#1F2933';
$textColor: '#7B8794';

$linkColor: '#DA127D';
$hoverLinkColor: '#A30664';

.App {
  background-color: #f5f7fa;
  min-height: 100vh;
  color: $textColor;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $headerColor;
  }

  a {
    text-decoration: none;
    color: $linkColor;
  }

  a:hover {
    color: $hoverLinkColor;
  }

  button {
    background-color: $linkColor;
    color: '#ffffff';
  }
}

.content {
  margin-top: 50px;
}
