@import "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Barlow:300,400,500,600,700");
@font-face {
  font-family: "Geomanist";
  src: url("assets/fonts/Geomanist-Regular.otf") format("opentype"); /* IE9 Compat Modes */
}

body {
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
}
