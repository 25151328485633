.static-container {
  max-width: 1450px;
  margin: 0 auto;
  height: 100%;
}

.LandingPage {
  color: #000000;
}

.pink-line {
  width: 103px;
  height: 6px;
  background-color: #fa7452;
  margin-top: 21px;
}
